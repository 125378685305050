import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  // {
  //   name: 'notFound',
  //   path: '/:path(.*)+',
  //   component: () => import('./view/login'),
  //   meta: {
  //     title: '登录',
  //   },
  // },
  {
    path: '/',
    redirect: '/login',
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('./view/login'),
    meta: {
      title: '登录',
    },
  },
  {
    name: 'user',
    path: '/user',
    component: () => import('./view/user'),
    meta: {
      title: '我的',
    },
  },
  {
    name: 'index',
    path: '/index',
    component: () => import('./view/index'),
    meta: {
      title: '首页',
    },
  },
  {
    name: 'details',
    path: '/details',
    component: () => import('./view/details'),
    meta: {
      title: '详情',
    },
  },
  {
    name: 'activityAdd',
    path: '/activityAdd',
    component: () => import('./view/activityAdd'),
    meta: {
      title: '添加活动',
    },
  },
  {
    name: 'activityDetails',
    path: '/activityDetails',
    component: () => import('./view/activityDetails'),
    meta: {
      title: '活动详情',
    },
  },
  {
    name: 'verificationList',
    path: '/verificationList',
    component: () => import('./view/verificationList'),
    meta: {
      title: '核销记录',
    },
  },
  {
    name: 'verification',
    path: '/verification',
    component: () => import('./view/verification'),
    meta: {
      title: '核销员管理',
    },
  },
  {
    name: 'activityData',
    path: '/activityData',
    component: () => import('./view/activityData'),
    meta: {
      title: '核销员管理',
    },
  },
  {
    name: 'myInfo',
    path: '/myInfo',
    component: () => import('./view/myInfo'),
    meta: {
      title: '基础信息',
    },
  },
  {
    name: 'moneyDetail',
    path: '/moneyDetail',
    component: () => import('./view/moneyDetail'),
    meta: {
      title: '账单明细',
    },
  },
  {
    name: 'withdrawal',
    path: '/withdrawal',
    component: () => import('./view/withdrawal'),
    meta: {
      title: '提现',
    },
  },
  {
    name: 'share',
    path: '/share',
    component: () => import('./view/share'),
    meta: {
      title: '分享',
    },
  },
  {
    name: 'register',
    path: '/register',
    component: () => import('./view/register'),
    meta: {
      title: '注册',
    },
  },
]

const router = createRouter({
  routes,
  history: createWebHashHistory(),
})

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title
  if (title) {
    document.title = title
  }
  next()
})

export { router }
