import { createApp } from 'vue'
import App from './App.vue'
import { Notify, Toast } from 'vant'
import { router } from './router'
import { createStore } from 'stupid-vuex'
import NavBar from '@/components/NavBar'
import http from '@/utils/request'

const store = createStore({
  activity: {},
  user: {},
})

const app = createApp(App)
app.use(router)
app.use(Notify)
app.use(Toast)
app.use(store)
app.config.globalProperties.$http = http
app.mount('#app')
app.component('NavBar', NavBar)
