<template>
  <div class="container">
    <router-view />
  </div>

  <van-tabbar route :safe-area-inset-bottom="true" @change="changeTabbar">
    <van-tabbar-item replace to="/index" :icon="homeIcon">首页</van-tabbar-item>
    <van-tabbar-item replace to="/user" :icon="myIcon">我的</van-tabbar-item>
  </van-tabbar>
</template>

<script>
import { Tabbar, TabbarItem } from 'vant'
export default {
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
  },
  data() {
    return {
      icons: [
        'https://dev-iot-v2.oss-cn-shenzhen.aliyuncs.com/market/picture/20220609_202204_62a1e5ecca1aa.png',
        'https://dev-iot-v2.oss-cn-shenzhen.aliyuncs.com/market/picture/20220609_201348_62a1e3fc39d51.png',
        'https://dev-iot-v2.oss-cn-shenzhen.aliyuncs.com/market/picture/20220609_202136_62a1e5d03338e.png',
        'https://dev-iot-v2.oss-cn-shenzhen.aliyuncs.com/market/picture/20220609_202231_62a1e6071cff5.png',
      ],
      homeIcon:
        'https://dev-iot-v2.oss-cn-shenzhen.aliyuncs.com/market/picture/20220609_201348_62a1e3fc39d51.png',
      myIcon:
        'https://dev-iot-v2.oss-cn-shenzhen.aliyuncs.com/market/picture/20220609_202136_62a1e5d03338e.png',
    }
  },
  mounted() {
    const url = window.location.href
    const name = url.substring(url.lastIndexOf('/') + 1, url.length)
    if (name === 'user') {
      this.homeIcon = this.icons[0]
      this.myIcon = this.icons[3]
    } else {
      this.homeIcon = this.icons[1]
      this.myIcon = this.icons[2]
    }
  },
  methods: {
    changeTabbar(index) {
      if (index === 0) {
        this.homeIcon = this.icons[1]
        this.myIcon = this.icons[2]
      } else {
        this.homeIcon = this.icons[0]
        this.myIcon = this.icons[3]
      }
    },
  },
}
</script>
<style lang="less">
:root {
  --van-tabbar-item-active-color: #000;
}
.van-notify {
  z-index: 99999 !important;
}
</style>
