<template>
  <div>
    <van-nav-bar :title="title" left-arrow @click-left="onClickLeft" />
  </div>
</template>

<script>
import { NavBar } from 'vant'
export default {
  name: 'NavBar',
  components: {
    [NavBar.name]: NavBar,
  },
  props: {
    title: String,
    close: Boolean,
  },
  methods: {
    onClickLeft() {
      history.back()
    },
  },
}
</script>
<style lang="less">
:root {
  --van-nav-bar-icon-color: #dcdcdc;
  --van-tabbar-item-text-color: #dcdcdc;
  --van-tabbar-item-active-color: #000;
}
</style>
